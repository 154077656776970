import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import SearchBlock from "components/search/SearchBlock";
import { page } from "components/page";
import ReactHtmlParser from "react-html-parser";

@withI18next(["common"])
@page
class Sitemap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    //console.log(this.props);
  }

  render() {
    return (
      <Layout {...this.props}>
        <div className="main sp" id="center">
          <SearchBlock />
          <section className="main_content">
            <div className="container">
              <a
                className="accesskey"
                href="javascript:;"
                id="aC"
                accessKey="C"
                title={this.props.t("jumperrwd.common.mainContenctArea")}>
                :::
              </a>
              <h2>{this.props.t("jumperrwd.common.sitemap")}</h2>
              <a href="javascript:history.back();" className="back">
                {this.props.t("hyint.common.table.back")}
              </a>
              <div className="detail_block">
                <div className="article_content">
                {ReactHtmlParser(this.props.t("jumperrwd.common.sitemapContent"))}
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default Sitemap;
